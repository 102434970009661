import type {
  Assets,
  ProductTileProps,
} from '@design-system/components/UI/UIProductTile.props'
import type { Sku } from '@commercelayer/sdk'

export const useShopTheLook = () => {
  const { pdpPath } = useRouteHelper()

  const getProducts = async (
    products: string[]
  ): Promise<ProductTileProps[]> => {
    const algoliaIndex = useAppConfig().currentAlgoliaIndex
    const { $cl } = useNuxtApp()

    const algoliaProducts = products.length
      ? await $fetch(`/api/getProducts/${algoliaIndex}`, {
          query: {
            mfcList: products.join(','),
          },
        })
      : []

    const filteredAlgoliaProducts = algoliaProducts.filter(isNonNullable)

    const skuCodes = filteredAlgoliaProducts
      ?.flatMap((algoliaProduct: any) => algoliaProduct?.size)
      ?.map((size: any) => size?.SKU)
      ?.filter((skuCode: any): skuCode is string => !!skuCode)

    const clPricesAndStocks: Sku[] = skuCodes.length
      ? (
          await Promise.all(
            chunk(skuCodes, 24).map(skuCodesChunk =>
              $cl.skus.list({
                include: [
                  'prices',
                  'stock_items',
                  'stock_items.reserved_stock',
                ],
                filters: {
                  code_in: skuCodesChunk?.join() ?? '',
                },
                pageSize: 24,
              })
            )
          )
        ).flat()
      : []

    const productsDetails: ProductTileProps[] = filteredAlgoliaProducts.map(
      (algoliaProduct: any) => {
        const productName = algoliaProduct?.Name
        const oldPrice = algoliaProduct?.FullPrice
        const sku = clPricesAndStocks.find(
          sku =>
            sku.code ===
            (algoliaProduct.size?.skuCode ?? algoliaProduct.size[0].SKU)
        )
        const skuPrice = sku?.prices?.[0]
        const price = skuPrice ? skuPrice?.amount_float! : oldPrice

        const gallery = algoliaProduct?.assets
          .map((g: any) => {
            if (g.type === 'mov') {
              return {
                URL: g.URL,
                type: 'TResponsiveVideo',
                priority: g.priority,
              }
            } else if (g.type === 'glb') {
              return {
                URL: g.URL,
                type: 'TResponsiveVideo',
                priority: g.priority,
              }
            } else if (g.type === 'jpg') {
              return {
                URL: g.URL,
                type: 'TResponsiveImage',
                priority: g.priority,
              }
            }
          })
          .filter((item: any) => item !== undefined)
          .sort((a: any, b: any) => {
            return (
              (a.priority || Number.MAX_VALUE) -
              (b.priority || Number.MAX_VALUE)
            )
          }) as Assets[]

        return {
          product: {
            name: productName,
            sku: algoliaProduct.objectID,
            brand: algoliaProduct.Brand,
            linkToProduct: pdpPath(algoliaProduct, false, true),
            isAvailable3d: false,
            images: gallery,
            price: {
              originalPrice: price,
            },
            labels: [algoliaProduct.Brand],
            inventory: {
              isAvailableOnline: clPricesAndStocks.some(
                el =>
                  el.code ===
                    (algoliaProduct.size?.skuCode ??
                      algoliaProduct.size[0].SKU) &&
                  el.stock_items?.some(item => item.quantity > 0)
              ),
              isAvailableInStore: algoliaProduct.size.some(
                (item: any) => item.avRet && item.avRet > 0
              ),
            },
            defaultColor: algoliaProduct.MacroColorDesc,
            colors: 0,
          },
          onlyImg: false,
          previewSlide: 0,
        }
      }
    )

    return productsDetails
  }

  return { getProducts }
}
